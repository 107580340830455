














































import { Component, Prop, Vue } from 'vue-property-decorator'

import { IProductCategory, defaultProductCategory, IProduct} from '../models/schemas' 
import { api } from '../lib/api'
import { routes } from '../lib/routes'
import { breadcrumb } from '../lib/breadcrumb'
import { basket } from '../lib/basket'

@Component
export default class ProductCategoryPage extends Vue {
    
    get routes(){ return routes }
    get basket(){ return basket }

    mounted() {
      this.getProductCategory()
    }

    @Prop()
    categorySlug:string | undefined
    
    public productCategory:IProductCategory = defaultProductCategory()

    getProductCategory():void {  
      api.call("productcategories/get", {slug: this.categorySlug, language:this.$i18n.locale})
        .then(res => {
            this.productCategory = res.getFirstItem() as IProductCategory

            breadcrumb.refresh( this.$route, {
                categoryName: this.productCategory.label
            })
        })   
    }
    
    addToBasket(product: IProduct):void{
        if(product.id)
            basket.addProduct(product, 1)
    }    
}

