


















































import { Component, Vue } from 'vue-property-decorator'
import { routes } from '../lib/routes'
import { api } from '../lib/api'
import { $$error } from '../lib/i18n' 
import { validation } from '../lib/validation'

@Component
export default class ChangePasswordPage extends Vue {

    get routes(){ return routes }

    private email:string = ""
    private token:string = ""
    private password:string = ""
    private password2:string = ""
    private submitted:boolean = false
    private hasError:boolean = false
    private errorMessage:string = ""
    private validation = validation()

    private created() {    
      this.email = this.$route.query.email && this.$route.query.email.toString()   
      this.token = this.$route.query.token && this.$route.query.token.toString()   
    }

    submitNewPassword():void {            
        if(this.validation.ko)
            return;
        api.call("auth/renewPasswordChange", {email : this.email, token:this.token, password:this.password})
            .then(res => {
                res.throwErrors()
                this.hasError = false
                this.submitted = true
                this.errorMessage=""
            })   
            .catch(err=>{
                this.hasError = true
                this.errorMessage=$$error(err)
            })
    }
}

