





















































import { Component, Vue } from 'vue-property-decorator'

import { routes } from '../lib/routes'
import { authentication } from '../lib/authentication' 
import { IProductCategory} from '../models/schemas' 
import { api } from '../lib/api'

@Component
export default class HomePage extends Vue {

    get authentication(){ return authentication }  
    get routes(){ return routes }
    public productCategories:Array<IProductCategory> = []

    mounted() {
      this.getProductCategories()
    }

    signOut():void
    {
        authentication.signOut()
        .then(() => {
            this.$router.replace(routes.toLogin())
        }) 
    } 

    getProductCategories():void {  
      api.call("productcategories/list", {language:this.$i18n.locale})
        .then(res => {
            this.productCategories = res.getItems() as IProductCategory[]                
        })   
    }    

}

