class Api
{
    call(verb: string, data = {}):Promise<ApiRet> {   
        return fetch(`/rest/${verb}`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {'Content-Type': 'application/json', 'mode': 'same-origin'},
            body: JSON.stringify(data),
        })
        .then( result => {
            if(result.status == 200)
                return result.json().then( ret=> new ApiRet(ret) )
            else 
                throw result.statusText
        })
    }    
}

class ApiRet
{
    public data:any;
    public errors:any;

    constructor(ret:any)
    {
        this.data = ret.data
        this.errors = ret.errors
    }

    hasErrors():boolean
    {
        return this.errors && this.errors.length > 0
    }

    throwErrors():this {
        if(this.hasErrors())
            throw this.errors[0].message
        return this
    }

    getErrors():any|undefined
    {
        if(this.hasErrors())
            return this.errors[0]
    }

    getFirstItem():any|undefined {
        if(!this.errors && this.data && this.data.items && this.data.items.length > 0)
            return this.data.items[0]
    }
    
    getItem():any|undefined {
        if(!this.errors && this.data && this.data.item )
            return this.data.item   
    }  
    
    getItems():any[] |undefined {
        if(!this.errors && this.data && this.data.items )
            return this.data.items   
    } 
}

export const api = new Api()