



















































import { Component,Vue } from 'vue-property-decorator'

import { authentication } from '../lib/authentication' 
import { routes } from '../lib/routes'
import { changeLocale, locales } from '../lib/i18n'
import LvlNavbarBasket from './lvl-navbar-basket.vue'


@Component({
  components:{LvlNavbarBasket}
})
export default class LvlMainNavbar extends Vue { 
  
    get authentication(){ return authentication }   

    get routes(){ return routes } 

    get langs(){ return locales } 

    get currentLangLabel():string{ 
      var currentLoc = this.$i18n.locale 
      var lang = this.langs.find(l=>l.locale == currentLoc)
      if(lang)
        return lang.label
      return "Lang" 
    }     
    
    signOut():void
    {
      authentication.signOut().then(() => this.$router.replace(routes.toLogin())) 
    }       

    changeLocale(locale:string):void
    {
      changeLocale(locale)
    }
}
