
import LoginPage from '../pages/login-page.vue'
import ForgotPasswordPage from '../pages/forgot-password-page.vue'
import ChangePasswordPage from '../pages/change-password-page.vue'
import HomePage from '../pages/home-page.vue'
import ProductCategoryPage from '../pages/product-category-page.vue'
import ProductPage from '../pages/product-page.vue'
import BasketPage from '../pages/basket-page.vue'
import OrderPage from '../pages/order-page.vue'
import MyOrdersPage from '../pages/myorders-page.vue'
import MyProfilePage from '../pages/myprofile-page.vue'

const AdminPage = ()=> import( /* webpackChunkName: "lvl-admin" */ '../pages/admin/admin-page.vue')
const AdminCreateOrdersPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-create-order-page.vue')
const AdminOrdersPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-orders-page.vue')
const AdminOrdersReceivedPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-orders-reception-page.vue')
const AdminOrdersProductionPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-orders-production-page.vue')
const AdminOrdersShippingPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-orders-shipping-page.vue')
const AdminOrdersDeliveredPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-orders-delivered-page.vue')
const AdminOrdersDeliverFailedPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-orders-deliverfailed-page.vue')
const AdminOrdersCancelledPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-orders-cancelled-page.vue')
const AdminProductsPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-products-page.vue')
const AdminProductCategoriesPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-product-categories-page.vue')
const AdminUsersPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-users-page.vue')
const AdminClientsPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-clients-page.vue')
const AdminBatchsPage = ()=> import( /* webpackChunkName: "lvl-admin" */'../pages/admin/admin-batchs-page.vue')

class Routes
{ 
    public login:any = { path: '/login', component: LoginPage, meta: { noAuth: true, nobreadcrumb: true, label:"login"  }}
    public forgotPassword:any = { path: '/forgot-password', component: ForgotPasswordPage, meta: { noAuth: true, nobreadcrumb: true, label:"forgot-password"  }}
    public changePassword:any = { path: '/change-password', component: ChangePasswordPage, props: this.queryProps(['email','token']) , meta: { noAuth: true, nobreadcrumb: true, label:"change-password"  }}
    public home:any = { path: '/', component: HomePage, meta: { noAuth: true, label:"home"} }
    public productCategory:any = { path: '/products/:categorySlug', component: ProductCategoryPage, props:true, meta: { noAuth: true, label:":categoryName", parent: this.home } }
    public productInCat:any = { path: '/products/:categorySlug/:productSlug', component: ProductPage, props:true, meta: { noAuth: true, label:":productName", parent: this.productCategory } }
    public product:any = { path: '/product/:productSlug', component: ProductPage, props:true, meta: { noAuth: true, label:":productName", parent: this.home } }
    public basket:any = { path: '/basket', component: BasketPage, meta: { noAuth: true, label:"basket", parent: this.home } } 
    public order:any = { path: '/order', component: OrderPage, meta: { noAuth: false, label:"order", parent: this.home } } 
    public myOrders:any = { path: '/my-orders', component: MyOrdersPage, meta: { label:"my_orders", parent: this.home } } 
    public myProfile:any = { path: '/my-profile', component: MyProfilePage, meta: { label:"my_profile", parent: this.home } } 

    public admin:any = { path: '/admin', component: AdminPage, meta: { label:"admin", parent: this.home } }
    public adminOrders:any = { path: '/admin/orders', component: AdminOrdersPage, meta: { label:"admin_orders", parent: this.admin } }
    public adminCreateOrder:any = { path: '/admin/orders/create-order', component: AdminCreateOrdersPage, meta: { label:"admin_create_order", parent: this.adminOrders } }
    public adminOrders_reception:any = { path: '/admin/orders/reception', component: AdminOrdersReceivedPage, props: this.queryProps(['order']), meta: { label:"order_status_reception", parent: this.adminOrders } }
    public adminOrders_production:any = { path: '/admin/orders/production', component: AdminOrdersProductionPage, props: this.queryProps(['order']), meta: { label:"order_status_production", parent: this.adminOrders } }
    public adminOrders_shipping:any = { path: '/admin/orders/shipping', component: AdminOrdersShippingPage, props: this.queryProps(['order']), meta: { label:"order_status_shipping", parent: this.adminOrders } }
    public adminOrders_delivered:any = { path: '/admin/orders/delivered', component: AdminOrdersDeliveredPage, props: this.queryProps(['order']), meta: { label:"order_status_delivered", parent: this.adminOrders } }
    public adminOrders_deliverfailed:any = { path: '/admin/orders/deliver-failed', component: AdminOrdersDeliverFailedPage, props: this.queryProps(['order']), meta: { label:"order_status_deliverfailed", parent: this.adminOrders } }
    public adminOrders_cancelled:any = { path: '/admin/orders/cancelled', component: AdminOrdersCancelledPage, props: this.queryProps(['order']), meta: { label:"order_status_cancelled", parent: this.adminOrders } }
    public adminProducts:any = { path: '/admin/products', component: AdminProductsPage, meta: { label:"products", parent: this.admin } }
    public adminProductCategories:any = { path: '/admin/product-categories', component: AdminProductCategoriesPage, meta: { label:"categories", parent: this.admin } }
    public adminUsers:any = { path: '/admin/users', component: AdminUsersPage, meta: { label:"admin_users", parent: this.admin } }
    public adminClients:any = { path: '/admin/clients', component: AdminClientsPage, meta: { label:"admin_clients", parent: this.admin } }
    public adminBatchs:any = { path: '/admin/batches', component: AdminBatchsPage, meta: { label:"admin_batchs", parent: this.admin } }

    public getRoutes():any[]{
        return [
            this.home, this.productCategory, this.productInCat, this.product, this.basket, this.order, this.myOrders, this.myProfile,
            this.login, this.forgotPassword, this.changePassword, 
            this.admin, 
                this.adminOrders, 
                    this.adminCreateOrder, 
                    this.adminOrders_reception, this.adminOrders_production, this.adminOrders_shipping, this.adminOrders_delivered, this.adminOrders_deliverfailed, this.adminOrders_cancelled,
                this.adminProducts, this.adminProductCategories,
                this.adminUsers,
                this.adminClients,
                this.adminBatchs
        ]  
    }  

    public toLogin(redirect?:string):any{
        const ret:any =  { path: this.login.path , query:{}}
        if(redirect)
           ret.query.redirect = redirect 
        return ret
    }
    
    public toProductCategory(categorySlug:string):any{
        return { path: this.productCategory.path.replace(":categorySlug", categorySlug) }
    }

    public toProduct(productSlug:string, categorySlug?:string):any{
        if(categorySlug)
            return { path: this.productInCat.path.replace(":categorySlug", categorySlug).replace(":productSlug", productSlug) }
        else
            return { path: this.product.path.replace(":productSlug", productSlug) }
    }

    private queryProps(names:string[])
    {
        return (route:any)=>{
            const prop:any={}
            names.forEach(name=>prop[name] = route.query[name])
            return prop
        }
    }

}

export const routes = new Routes()


