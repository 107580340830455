










import { Component, Vue } from 'vue-property-decorator'
import { onLocaleChanged, offLocaleChanged } from './lib/i18n'
import LvlMainNavbar from './components/lvl-main-navbar.vue'
import LvlBreadcrumb from './components/lvl-breadcrumb.vue'
import { authentication } from './lib/authentication'  
import { $$t } from './lib/i18n' 

@Component({
   components:{LvlMainNavbar, LvlBreadcrumb}
})
export default class App extends Vue {  
    componentKey:number = 0

    created():void {
      onLocaleChanged( this.onLocaleChanged)
      document.title=$$t("brand_title")
    }

    destroyed():void {
      offLocaleChanged( this.onLocaleChanged)
    }

    mounted():void {
      authentication.checkSignedIn() 
    }  

    forceReRender():void
    { 
      this.componentKey+=1
      document.title=$$t("brand_title")
    }

    onLocaleChanged():void
    { 
      this.forceReRender()
    }
}
