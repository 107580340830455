import Vue from 'vue'
import VueI18n from 'vue-i18n' 
// @ts-ignore
import fr from '../locales/fr.js'
// @ts-ignore
import ky from '../locales/ky.js'
// @ts-ignore
import ru from '../locales/ru.js'
import { api } from '../lib/api'

Vue.use(VueI18n)

export const locales = [
  {locale: 'ru', label: "RU"},
  {locale: 'ky', label: "KG"},
  {locale: 'fr', label: "FR"}
]

export const i18n = new VueI18n({
    locale: getStartLocale(), 
    fallbackLocale: locales[0].locale,
    messages : {
      ru : ru,
      ky : ky,
      fr : fr
    },
    silentTranslationWarn: true,
    silentFallbackWarn: true
  })

function getStartLocale():string
{
  let loc = localStorage.getItem("locale") 
  if(!loc)
    loc = navigator.language
  if(!locales.find(l=>l.locale == loc))
    loc = locales[0].locale
  return loc
}

export function $$t(... args:any):string{ return i18n.t(args).toString() }

export function $$error(err:String){
  return $$t('error_'+(err && err.toLowerCase() || "void"))
}

export function changeLocale(locale:string)
{
  if(i18n.locale != locale)
  {
    i18n.locale = locale
    localStorage.setItem("locale",locale)
    api.call("users/setLang", {lang: locale}).then(()=>{
      _onLocaleChangedHandlers.forEach(handler => handler( locale))
    })
    i18n
  }
}

let _onLocaleChangedHandlers:((locale:string)=>void)[] = []

export function onLocaleChanged(handler:(locale:string)=>void):void
{
    if(_onLocaleChangedHandlers.indexOf(handler) == -1)
        _onLocaleChangedHandlers.push(handler)
}

export function offLocaleChanged(handler:(locale:string)=>void):void
{
    _onLocaleChangedHandlers = _onLocaleChangedHandlers.filter(h=>h!=handler)
}