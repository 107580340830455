import Vue from 'vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import App from './App.vue'
import { routes } from './lib/routes'
import { router} from './lib/router'
import { i18n} from './lib/i18n'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

router.addRoutes(routes.getRoutes())

new Vue({
  router : router,
  i18n : i18n,
  render: h => h(App)
}).$mount('#app')
