import Vue from 'vue'
import VueRouter from 'vue-router'

import { routes } from './routes'
import { authentication } from './authentication' 

Vue.use(VueRouter)

export const router = new VueRouter({})

router.beforeEach((to, from, next) => {
  const requireAuth = !(to.meta && to.meta.noAuth)
  if (requireAuth && !authentication.authenticated){ // &&  to.matched.some(record => !(record.meta && record.meta.noAuth))) {
    // this route requires auth, check if logged in. if not, redirect to login page.
    authentication.checkSignedIn()
      .then(()=>next())
      .catch(()=>next(routes.toLogin(to.fullPath)))
  } else {
    next() // make sure to always call next()!
  }
}) 