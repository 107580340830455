











































import { Component, Prop, Vue } from 'vue-property-decorator'

import { IProduct, defaultProduct} from '../models/schemas' 
import { api } from '../lib/api'
import { breadcrumb } from '../lib/breadcrumb'
import { basket } from '../lib/basket'

@Component
export default class ProductPage extends Vue {

    get basket(){ return basket } 

    mounted() {
      this.getProduct()
    }

    @Prop()
    categorySlug:string | undefined

    @Prop()
    productSlug:string | undefined

    product:IProduct = defaultProduct()

    getProduct():void {  
        api.call("products/get", {slug: this.productSlug, language:this.$i18n.locale}).then(res => {
            this.product = res.getFirstItem() as IProduct

            breadcrumb.refresh( this.$route, {
                productName: this.product.label,
                categoryName: this.product.productCategory && this.product.productCategory.label
            })
        })   
    } 

    addToBasket():void{
        if(this.product.id)
            basket.addProduct(this.product, 1)
    }
}

