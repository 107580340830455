





















































import { Component, Vue } from 'vue-property-decorator'
import { api } from '../lib/api'
import { $$error } from '../lib/i18n' 
import { validation } from '../lib/validation'

@Component
export default class ForgotPasswordPage extends Vue {

    private email:string = ""
    private submitted:boolean = false
    private hasError:boolean = false
    private errorMessage:string = ""
    private validation = validation()

    submitEmailForRecovery():void {            
        if(this.validation.ko)
            return;
        api.call("auth/renewPasswordInit", {email : this.email, from:document.location.protocol+"//"+document.location.host})
            .then(res => {                
                res.throwErrors()
                this.hasError = false
                this.submitted = true
                this.errorMessage=""
            })   
            .catch(err=>{
                this.hasError = true
                this.errorMessage=$$error(err)
            })
    }
}

