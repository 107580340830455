import Vue from 'vue'

import { IUser, IClient } from '../models/schemas'
import { api } from '../lib/api'
import { i18n} from '../lib/i18n'

class Authentication
{
    private _authenticated: boolean = false
    public get authenticated():boolean { return this._authenticated}

    private _user:IUser|undefined = undefined
    public get user():IUser|undefined { return this._user}

    private _userId: string = ""
    public get userId():string { return this._userId}

    private _userName: string  = ""
    public get userName():string { return this._userName}

    private _userEmail: string = ""
    public get userEmail():string { return this._userEmail}

    private _userClient: boolean = false
    public get userClient():boolean { return this._userClient}

    private _userInternal: boolean = false
    public get userInternal():boolean { return this._userInternal}

    private _userAdmin: boolean = false
    public get userAdmin():boolean { return this._userAdmin}

    private _client:IClient|undefined = undefined
    public get client():IClient|undefined { return this._client}

    private _clientId: string = ""
    public get clientId():string { return this._clientId}

    private _clientName: string = ""
    public get clientName():string { return this._clientName}

    private _lastError: string =""
    public get lastError():string { return this._lastError}
    
    private onAuthChangedHandlers:(()=>void)[] = []

    checkSignedIn():Promise<boolean>
    {
      const p:Promise<boolean> = new Promise((resolve, reject): void => { 
        api.call("auth/isAuthenticated")
          .then(res=>{        
            res.throwErrors()
            if(res.data.auth)
            {
                if(!this._userId)
                    this.getUser()
                resolve(res.data.auth) 
            }   
            else
                reject(this.setError(""))
          })
          .catch(err=>reject(this.setError(err || "error")))
      })

      p.catch( ()=> this.setUser(null))

      return p;
    }

    signIn(email:string, password:string):Promise<IUser>
    {
        const p:Promise<IUser> = new Promise((resolve, reject): void => {
            api.call("auth/authenticate", {email, password})
            .then(res => {         
                res.throwErrors()
                if(res.data.auth)
                {
                    this.getUser()
                        .then(user=>resolve(user))     
                        .catch(err=>reject(err))  
                }   
                else
                    throw "unexpected while authenticating"
            })
            .catch(err=>reject(this.setError(err || "error")))
        })  

        p.catch( ()=> this.setUser(null))
  
        return p
    }

    getUser():Promise<IUser>
    {
        const p:Promise<IUser> = new Promise((resolve, reject): void => { 
            api.call("auth/getUser")
              .then(res=>{    
                res.throwErrors()
                if(this.setUser(res.getItem() as IUser))
                    resolve(res.getItem()) 
                else
                    throw "unexpected error while retrieving user"
              })
              .catch(err=>reject(this.setError(err || "error")))
          })
    
          p.catch( ()=> this.setUser(null))
    
          return p;       
    }
    
    signOut():Promise<void>
    {
        const p:Promise<void> = new Promise((resolve, reject): void => {
            api.call("auth/unAuthenticate")
            .then(res => {                
                res.throwErrors()
                this.setUser(null)  
                resolve()
            })    
            .catch(()=>reject())  
        })

        p.catch( ()=> this.setUser(null))
  
        return p
    } 

    private setError(err:string):string
    {
        this._lastError = err
        return err;
    }

    private setUser(user:IUser|null):boolean
    {
        const oldAuthStatus:boolean = this._authenticated
        if(!user || !user.id || !user.name)
        {
            this._authenticated = false
            this._user = undefined
            this._userId = ""
            this._userName = ""
            this._userClient = false
            this._userInternal = false
            this._userAdmin = false

            this.setClient(null)
        }
        else
        {
            this.setError("")
            this._authenticated = true
            this._user = user;
            this._userId = user.id
            this._userName = user.name   
            this._userEmail = user.email    
            this._userClient = !!user.client
            this._userInternal = user.profile  == "ADMIN" || user.profile  == "INTERNAL" 
            this._userAdmin = user.profile  == "ADMIN" 
            if(user.client) 
                this.setClient(user.client)
            if(user.language)
                i18n.locale = user.language
        }
        if(oldAuthStatus != this._authenticated)
            this.onAuthChangedHandlers.forEach(handler => handler());
        return this._authenticated
    }

    private setClient(client:IClient|null):void
    {
        if(!client)
        {
            this._client = undefined
            this._clientId = ""    
            this._clientName = ""   
        }
        else
        {
            this._client = client
            this._clientId = client.id     
            this._clientName = client.name 
        }
    }   

    public onAuthChanged(handler:()=>void):void
    {
        if(this.onAuthChangedHandlers.indexOf(handler) == -1)
            this.onAuthChangedHandlers.push(handler)
    }

    public offAuthChanged(handler:()=>void):void
    {
        this.onAuthChangedHandlers = this.onAuthChangedHandlers.filter(h=>h!=handler)
    }    
}

export const authentication = Vue.observable(new Authentication())