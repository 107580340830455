



























































import { Component, Vue } from 'vue-property-decorator'
import { routes } from '../lib/routes'
import { authentication } from '../lib/authentication' 
import { $$t, $$error } from '../lib/i18n' 

@Component
export default class LoginPage extends Vue {

    public email:string = ""
    public password:string = ""
    public hasError:boolean = false
    public errorMessage:string = ""

    get routes(){ return routes }

    mounted():void {
        if(authentication.lastError)
        {
            this.$bvToast.toast(authentication.lastError, {
                title: $$t('error'),
                variant: "warning",
                solid: true,
                toaster : "b-toaster-bottom-right",
                "auto-hide-delay":1000,
                "is-status" : true
            }) 
        }
    }

    submitCredentials():void {      
        authentication.signIn(this.email, this.password)
            .then(() => {
                this.hasError = false
                this.errorMessage=""
                this.$router.replace(this.$route.query.redirect || routes.home)
            })   
            .catch(err=>{
                this.hasError = true
                if(err && err.indexOf("[passwordAuth:failure]")!=-1)
                    err="secret_mismatch"
                this.errorMessage=$$error(err)
            })
    }
}

