import Vue from 'vue'
import { $$t } from '../lib/i18n' 
import { router} from '../lib/router'

class Breadcrumb
{
    public items:any[] = []

    constructor()
    {
        router.afterEach(()=>{
            this.refresh(router.currentRoute)
        })
    }

    public refresh(route:any, props:any = undefined)
    {
        const routeParams = route.params
        const tmpItems = []
        while(route != null)
        {
            if(route.meta && route.meta.label)
            {
                let text = route.meta.label
                const initText = text 
                let path = route.path
                if(props)
                {
                    for(const n in props)
                    {
                        text = text.replace(":"+n, props[n])
                    } 
                }        
                if(routeParams)   
                {
                    for(const n in routeParams)
                    {
                        path = path.replace(":"+n, routeParams[n])
                    }                  
                }
                if(text.length>0 && initText == text && text[0]!=":")
                    text = $$t(text)
    
                if(text.length>0 && text[0]!=":")
                    tmpItems.push({to:path, text:text})
            }

            route = route.meta && route.meta.parent
        }

        this.items = tmpItems.reverse()
    }
}

export const breadcrumb = Vue.observable(new Breadcrumb())