








import { Component,Vue } from 'vue-property-decorator'
import { basket } from '../lib/basket'
import { IOrderItem} from '../models/schemas' 
import { routes } from '../lib/routes'
import { $$t } from '../lib/i18n' 

@Component
export default class LvlNavbarBasket extends Vue {  
 
    get routes(){ return routes } 
    get basket(){ return basket } 
    
    created():void {
      basket.onItemAdded( this.onBasketItemAdded)
    }

    destroyed():void {
      basket.offItemAdded( this.onBasketItemAdded)
    }

    private onBasketItemAdded(item:IOrderItem):void
    {
      
        this.$bvToast.toast(this.$i18n.t('basket_added_item', { product: item.product.label }).toString(), {
          title: $$t('basket'),
          variant: "info",
          solid: true,
          toaster : "b-toaster-bottom-right",
          "auto-hide-delay":1000,
          "is-status" : true,
          "header-class" : "invisible"
        })      
    }
      
}
