














































































import { Component, Vue } from 'vue-property-decorator'
import { $$t, $$error } from '../lib/i18n' 
import { authentication } from '../lib/authentication'
import { basket } from '../lib/basket'
import { routes } from '../lib/routes'
import { IOrder} from '../models/schemas' 
import LvlOrderDetail from '../components/lvl-order-detail.vue'

@Component({
   components:{LvlOrderDetail}
})
export default class OrderPage extends Vue {   

    private get basket(){ return basket } 
    private get routes(){ return routes } 
    private get authentication(){ return authentication } 
    private get client(){ return authentication.client } 

    private orderConfirmedVisible:boolean = false
    private orderConfirmedPending:boolean = false
    private orderConfirmedSuccess:boolean = false
    private orderConfirmedOrderId:string=""
    private order:IOrder|undefined = undefined


    mounted() {
      this.basket.check()
    }


    private cancel():void
    {
      this.$router.push(routes.basket)
    }

    private confirmOrder():void
    {
      this.orderConfirmedVisible = true
      this.orderConfirmedPending = true
      this.basket.order()
        .then(order=>{
          this.orderConfirmedSuccess = true
          this.orderConfirmedOrderId = order?.orderid || ""
          this.orderConfirmedPending = false
        })  
        .catch(err=>{
          this.showError(err)
          this.orderConfirmedSuccess = false
          this.orderConfirmedPending = false
        })
    }

    private confirmOrderFinished():void
    {
      this.$router.push(routes.myOrders)
    }

    private showError(err:any){
        this.$bvToast.toast($$error(err), {
          title: $$t('error'),
          toaster: 'b-toaster-bottom-full',
          noAutoHide: true,
          variant:'danger'
        })
    }    
}

