var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"foot-clone":true,"striped":_vm.striped,"head-variant":"light","hover":"","small":"","outlined":"","responsive":"","sort-by":"section,label","sort-compare":_vm.orderItemySortCompare},scopedSlots:_vm._u([{key:"cell(label)",fn:function(ref){
var item = ref.item;
return [(item.slug)?_c('b-link',{attrs:{"to":_vm.routes.toProduct(item.slug)}},[_vm._v(_vm._s(item.label))]):_vm._e(),(!item.slug)?_c('span',[_vm._v(_vm._s(item.label))]):_vm._e()]}},{key:"cell(unitprice)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.coalesce(item.unitprice,"-"))+" ")]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.coalesce(item.price,"-"))+" ")]}},{key:"cell(quantity)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.coalesce(item.quantity,"-"))+" ")]}},{key:"foot(label)",fn:function(){return [_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.$t('total')))])]},proxy:true},{key:"foot(quantity)",fn:function(){return [_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.quantity))])]},proxy:true},{key:"foot(price)",fn:function(){return [_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.order.price * 1 + _vm.order.shipping * 1)+" "+_vm._s(_vm.$t('unit_currency')))])]},proxy:true},{key:"foot()",fn:function(){return [_c('span',{staticClass:"text-bold"})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }