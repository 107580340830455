export interface IUser{
	id: string
	email: string
	name: string
	password: any
	client?: IClient
	profile: string
	language?: string
	phone_number?: string
	renewpassword_token?: string
	renewpassword_timestamp?: string
	isActive: boolean
}

export function defaultUser(): IUser{ return {
	id: '',
	email: '',
	name: '',
	password: '',
	client: defaultClient(),
	profile: '',
	language: '',
	phone_number: '',
	renewpassword_token: '',
	renewpassword_timestamp: '',
	isActive: false,
}}

export interface IClient{
	id: string
	name: string
	slug?: any
	users?: IUser[]
	orders?: IOrder[]
	shippingArea: string
	shippingAddress_name?: string
	shippingAddress_details?: string
	shippingAddress_street?: string
	shippingAddress_postcode?: string
	shippingAddress_city?: string
	shippingAddress_country?: string
	useShippingAddessAsBillingAddress?: boolean
	billingAddress_name?: string
	billingAddress_details?: string
	billingAddress_street?: string
	billingAddress_postcode?: string
	billingAddress_city?: string
	billingAddress_country?: string
	isActive: boolean
}

export function defaultClient(): IClient{ return {
	id: '',
	name: '',
	slug: '',
	users: [],
	orders: [],
	shippingArea: '',
	shippingAddress_name: '',
	shippingAddress_details: '',
	shippingAddress_street: '',
	shippingAddress_postcode: '',
	shippingAddress_city: '',
	shippingAddress_country: '',
	useShippingAddessAsBillingAddress: false,
	billingAddress_name: '',
	billingAddress_details: '',
	billingAddress_street: '',
	billingAddress_postcode: '',
	billingAddress_city: '',
	billingAddress_country: '',
	isActive: false,
}}

export interface IProduct{
	id: string
	isActive?: boolean
	productCategory?: IProductCategory
	name: string
	slug?: any
	price?: any
	shipping_TOK?: any
	shipping_KAR?: any
	shipping_BIS?: any
	shipping?: any
	weight?: number
	image?: string
	sortIndex?: number
	label_ru?: string
	label_ky?: string
	label_fr?: string
	label?: any
	description_ru?: string
	description_ky?: string
	description_fr?: string
	description?: any
}

export function defaultProduct(): IProduct{ return {
	id: '',
	isActive: false,
	productCategory: defaultProductCategory(),
	name: '',
	slug: '',
	price: '',
	shipping_TOK: '',
	shipping_KAR: '',
	shipping_BIS: '',
	shipping: '',
	weight: 0,
	image: '',
	sortIndex: 0,
	label_ru: '',
	label_ky: '',
	label_fr: '',
	label: '',
	description_ru: '',
	description_ky: '',
	description_fr: '',
	description: '',
}}

export interface IProductCategory{
	id: string
	name: string
	image?: string
	sortIndex: number
	slug?: any
	products?: IProduct[]
	isActive?: boolean
	label_ru?: string
	label_ky?: string
	label_fr?: string
	label?: any
	description_ru?: string
	description_ky?: string
	description_fr?: string
	description?: any
}

export function defaultProductCategory(): IProductCategory{ return {
	id: '',
	name: '',
	image: '',
	sortIndex: 0,
	slug: '',
	products: [],
	isActive: false,
	label_ru: '',
	label_ky: '',
	label_fr: '',
	label: '',
	description_ru: '',
	description_ky: '',
	description_fr: '',
	description: '',
}}

export interface IOrder{
	id: string
	orderid?: string
	client: IClient
	date: string
	clientOrdinal?: number
	orderitems: IOrderItem[]
	price: any
	shipping: any
	status: string
	isArchived?: boolean
}

export function defaultOrder(): IOrder{ return {
	id: '',
	orderid: '',
	client: defaultClient(),
	date: '',
	clientOrdinal: 0,
	orderitems: [],
	price: '',
	shipping: '',
	status: '',
	isArchived: false,
}}

export interface IOrderItem{
	id: string
	product: IProduct
	order: IOrder
	quantity: number
	price: any
	shipping: any
	batch?: IBatch
}

export function defaultOrderItem(): IOrderItem{ return {
	id: '',
	product: defaultProduct(),
	order: defaultOrder(),
	quantity: 0,
	price: '',
	shipping: '',
	batch: defaultBatch(),
}}

export interface IBatch{
	id: string
	product: IProduct
	orderItems?: IOrderItem[]
	productionQuantity: number
	remainingQuantity: number
	productionDate: string
	expirationDate: string
	isArchived?: boolean
}

export function defaultBatch(): IBatch{ return {
	id: '',
	product: defaultProduct(),
	orderItems: [],
	productionQuantity: 0,
	remainingQuantity: 0,
	productionDate: '',
	expirationDate: '',
	isArchived: false,
}}

