















































import { Component, Prop, Vue } from 'vue-property-decorator'
import moment  from 'moment';
import { IOrder, IOrderItem, defaultOrder} from '../models/schemas' 
import { routes } from '../lib/routes'
import { $$t } from '../lib/i18n'

interface IOrderDetailRow
{
  label:string,
  slug?:string,
  quantity:number,
  unitprice:number,
  price:number,
  batch:string,
  section:number
}

@Component
export default class LvlOrderDetail extends Vue {  
 
    get routes(){ return routes } 

    @Prop({default:false})
    striped:boolean|undefined

    @Prop({default:defaultOrder()})
    order:IOrder|undefined
    
    @Prop({default:false})
    private withbatch:Boolean|undefined

    public get items():IOrderDetailRow[]{ 
      if(!this.order)
        return []
      var arr = this.order.orderitems.map(oi=>({
        label : oi.product.label,
        slug: oi.product.slug,
        quantity : oi.quantity,
        unitprice : oi.product.price,
        price : oi.price,
        batch : oi.batch && this.formatDate(oi.batch.productionDate),
        section: 0
      }) as IOrderDetailRow)
   
      arr.push({
        label : $$t("shipping"),
        slug: "",
        quantity : 0,
        unitprice : 0,
        price : this.order.shipping,
        batch:"",
        section: 1
      })
      return arr
    }

    public get quantity():number{  return this.items.map(i=>i.quantity).reduce((a,b)=>a+b)  }    

    public fields:any[] = this.getFields()

    private getFields():any{
      const ret = []
      ret.push({key:'label', label: $$t('product') })
      ret.push({key:'unitprice', label: $$t('product_unit_price')+" ("+$$t('unit_currency')+")"})
      ret.push({key:'quantity', label: $$t('order_row_quantity') })
      ret.push({key:'price', label: $$t('order_row_price')+" ("+$$t('unit_currency')+")" })
      if(this.withbatch)
        ret.push({key:'batch', label: $$t('admin_batch') })
      return ret
    }

    private coalesce(value:any, ifnull:string):string{
      if(value)
        return value.toString() 
      else
        return ifnull
    }


    private orderItemySortCompare(a:IOrderItem, b:IOrderItem, key:string):number|boolean {
      var keys = key.split(",")
      for (var i=0; i<keys.length; i++)
      {
        if (keys[i] === 'productName') 
        {
          var pa = (a.product && a.product.label)
          var pb = (b.product && b.product.label)
          if(pa<pb)
            return -1
          else if(pb<pa)
            return 1
        }
      }
      return false
    }     

    private formatDate(value:string):string{
      const m = moment(value,'YYYY-MM-DD"T"HH:mm:ss.SZ')
      return m.format("YYYY-MM-DD") 
    }
}
