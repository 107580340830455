import Vue from 'vue'

export class Validation
{
    private disabled:Boolean = false
    private fieldsState:Map<string,string> = new Map<string,string>()

    get ok():boolean{
      return this.fieldsState.size == 0
    }
    
    get ko():boolean{
      return !this.ok
    }

    enable():Validation
    {
      this.disabled = false
      return this
    }

    disable():Validation
    {
      this.disabled = true
      return this
    }

    reset():void{
      this.fieldsState.clear()
    }

    set(name:string, status:boolean, message:string):boolean|null{

       if(!status)
         this.fieldsState.set(name,message)     
       else
         this.fieldsState.delete(name)

       return (this.disabled || status) ? null:false  
     }   

    required(v:any, name:string):boolean|null{
      return this.set(name, !!v, "required")     
    }     

    requiredif(v:any, name:string, ifcond:boolean):boolean|null{
      return this.set(name, !ifcond || !!v, "required")     
    }     

    email(v:any, name:string):boolean|null{
      const regexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      return this.set(name, v && regexp.test(v), "required")     
    }   
    
    same(v1:string, v2:string, name:string):boolean|null{
      return this.set(name, (!v1 && !v2) || (v1 == v2), "donotmatch")     
    }     
}

export function validation():Validation{
    return Vue.observable(new Validation())
}