



























































































import { Component, Vue } from 'vue-property-decorator'
import { api } from '../lib/api'
import { IUser, defaultUser} from '../models/schemas' 
import { $$t, $$error } from '../lib/i18n' 
import { validation } from '../lib/validation'
import { changeLocale } from '../lib/i18n'


@Component
export default class MyProfilePage extends Vue {

    private item:IUser = defaultUser()
    private busy:boolean = false    
    private locales:Array<any> = []

    private validation = validation().disable()


    private itemIsClient():boolean {
      return !!(this.item && this.item.client)
    }

    private mounted() {
        $$t("pipo")
      Promise.all([
        this.getLocales()
      ])
      .then(()=>this.getItem())
    }

    private getItem():void {  
      this.busy = true  

      api.call("users/get").then(res => {
        this.busy = false
        this.item = res.getItem() as IUser
        this.validation.enable()
      })   
    }    

    private getLocales():Promise<void> {  
      return api.call("users/locales").then(res => {
        this.locales = res.data.map((p:string)=> ({label:$$t("locale_"+p.toLowerCase()), locale:p}))
      })   
    }

    private save()
    {
      if(this.validation.ko)
        return;
      this.busy = true  

      api.call("users/update", {item:this.item }).then(ret => {
        ret.throwErrors()
        var savedUser = ret.getItem() as IUser
        if(savedUser.language && savedUser.language != this.$i18n.locale )
            changeLocale(savedUser.language)
        this.getItem();
        this.showSuccess()
      }) 
      .catch(err => this.showError(err))     
    }    

    private showError(err:any){
        this.$bvToast.toast($$error(err), {
          title: $$t('error'),
          toaster: 'b-toaster-bottom-full',
          noAutoHide: true,
          variant:'danger'
        })
    }    

    private showSuccess(){
        this.$bvToast.toast($$t('save_success'), {
          toaster: 'b-toaster-bottom-right',
          variant:'success'
        })
    }        

    private useShippingAddessAsBillingAddressChanged(v:boolean){
      var client = this.item.client
      if(v && client)
      {
        client.billingAddress_name = client.shippingAddress_name
        client.billingAddress_details = client.shippingAddress_details
        client.billingAddress_street = client.shippingAddress_street
        client.billingAddress_postcode = client.shippingAddress_postcode
        client.billingAddress_city = client.shippingAddress_city
        client.billingAddress_country = client.shippingAddress_country
      }
    }    
}

