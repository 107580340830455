





import { Component, Vue } from 'vue-property-decorator'

import { breadcrumb } from '../lib/breadcrumb'

@Component
export default class LvlBreadcrumb extends Vue { 

    get breadcrumb(){ return breadcrumb }     
    
    get showbreadcrumb():boolean{
      return !(this.$route.meta && this.$route.meta.nobreadcrumb)
    }    
}
