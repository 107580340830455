module.exports = {

    brand_title: "Ла воа лакте",

    // general
    email: "Email",
    password: "Password",
    password_confirm: "Password (confirmation)",
    signin: "Sign in",
    signout: "Sign out",
    error: "Error",
    cancel: "Cancel",
    ok: "OK",
    save: "Save",
    save_success: "Data has been saved",
    total: "Total",
    remove: "Remove",
    date: "Date",
    show_details: "View details",
    hide_details: "Hide détails",
    all: "All",
    selection: "Selection",
    language: "Language",

    // units
    unit_grams : "grams",
    unit_kilograms : "kg",
    unit_currency: "сом",


    // menu
    login: "Log in",
    home: "Home",
    my_basket: "My basket",
    my_orders: "My orders",
    my_profile: "My profile",

    // login page
    login_page_title: "Log in",
    forgot_password: "Forgot password ?",

    // forgot password page
    forgot_password_page_title: "Reset password",
    forgot_password_text: "To reset your password, please type ypour email address, we will send you a password reset link",
    forgot_password_submit: "Send password recovery email",
    forgot_password_submitted: "If your email address belongs to a valid account, a password reset email has been sent to you",
    
    // reset password page
    reset_password_page_title: "Reset password",
    reset_password_text: "Please type your new password",
    reset_password_submit: "Reset password",
    reset_password_submitted: "Your password has been changed",      

    // home page
    view_basket: "View my basket",    
    view_orders: "View my orders",
    our_products : "Our products",


    // basket
    basket: "Basket",
    basket_page_title: "Basket",
    basket_empty: "Your basket is empty",
    add_to_basket: "Add to basket",
    basket_added_item: "The product '{product}' has been added to the basket",
 
 
    // products
    category: "Category",
    categories: "Categories",
    product: "Product",
    products: "Products",
    product_unit_price: "Unit. price",
    product_shipping_price: "Shipping",
    view_products: "View products",
    view_product: "View product",
    weight: "Weight",


    // order
    order_page_title: "Order",
    order_row_quantity: "Quantity",
    order_row_price: "Sub-total",
    order_price: "Total",
    order_status: "Status",
    order_status_basket: "Basket",
    order_status_reception: "Received",
    order_status_production: "Production",
    order_status_shipping: "Shipped",
    order_status_delivered: "Delivered",
    order_status_deliverfailed: "Unable to deliver",
    order_status_cancelled: "Cancelled",
    order_do: "Order",
    order_confirm: "Confirm order",
    order_confirmed: "Your order is confirmed",
    order_your_order_id: "Your order id is :",
    order_order_id: "Order id",
    order_confirm_failed: "An error occurred while confirming your order, please try again later.",   
    order_reorder: "Reorder",
    shipping:"Shipping",   
    

    // mail
    mail_hello: "Hello,",
    mail_polite: "Sincerely,",
    mail_signature: "The Lavoielactée team",  

    // address
    address_name :"Name",
    address_details :"Details",
    address_street :"Number / Street",
    address_postcode :"Postcode",
    address_city :"City",
    address_country :"Country",
    shipping_address :"Shipping address",
    billing_address :"Billing address",
    address_use_shipping_as_billing :"Same as shipping address",
    phone_number: "Phone", 

    // errors
    error_void: "Error",
    error_secret_mismatch :  "The email or password are incorrect",
    error_invalid_user: "Invalid user",
    error_invalid_token: "Invalid link",
    error_expired_token: "The link has expired",
    error_weak_password: "The password is weak",
    error_passwords_mismatch : "Passwords do not match",

    // admin
    admin: "Admin",
    admin_orders: "Orders",
    admin_create_order: "Create an order",
    admin_client: "Client",
    admin_clients: "Clients",
    admin_users: "Users",
    admin_shipping_area: "Shipping area",
    admin_manage: "Manage",
    admin_append: "Append",
    admin_save: "Save",
    admin_delete: "Delete",
    admin_confirm_delete: "Are you sure you want to delete this item?",

    admin_isvisible: "Visible?",
    admin_visible: "Visible",
    admin_hidden: "Hidden",
    admin_isactive: "Active?",
    admin_active: "Enabled",
    admin_inactive: "Disabled",
    admin_isarchived: "Archived?",
    admin_archived: "Archived",
    admin_nonarchived: "Visible",
    admin_archive: "Archive",
    admin_unarchive: "Unarchive",
    admin_name: "Name",
    admin_slug: "Path",
    admin_label: "Label",
    admin_description: "Description",
    admin_sortindex: "Order",
    admin_image: "Image",
    admin_image_max: "max. 1Mb",

    admin_profile: "Profile",
    admin_profile_admin: "Administrator",
    admin_profile_internal: "Internal",
    admin_profile_client: "Client",

    admin_batch: "Batch",
    admin_batchs: "Batches",
    admin_productionquantity: "Production quantity",
    admin_remainingquantity : "Available quantity",
    admin_productiondate: "Production date",
    admin_expirationdate: "Expiration date",
    admin_stock: "Stock",

    // renew password
    renewpassword_email_title:"Password change on lavoielactee",
    renewpassword_email_body1:"To change your password, click on : ",
    renewpassword_email_body2:"This link is only valid for 24 hours.",

    // languages
    locale_fr: "French",
    locale_ru: "Russian",
    locale_ky: "Kyrgyz"
}