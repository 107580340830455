import Vue from 'vue'

import { api } from '../lib/api'
import { IOrder,IOrderItem, IProduct, defaultOrder} from '../models/schemas' 


export class Basket
{

    
    private _basketOrder:IOrder = this.loadBasketOrder()
    public get basketOrder():IOrder{ return this._basketOrder }
    public get items():IOrderItem[]{ return this._basketOrder && this._basketOrder.orderitems || [] }
    public get price():number{ return this._basketOrder.price }
    public get quantity():number{ 
        if(this._basketOrder && this._basketOrder.orderitems)
            return this._basketOrder.orderitems.map(i=>i.quantity).reduce((a,b)=>a+b) 
        else
            return 0
    }

    private _onItemAddedHandlers:((item:IOrderItem)=>void)[] = []

    public addProduct(product: IProduct, quantity: number):void
    {
        this.addItem({
            id: '',
            product : product,
            quantity :  quantity,
            price : product.price * quantity,
            shipping: product.shipping * quantity,
            order: null as unknown as IOrder
        })
    }

    public addItem(item:IOrderItem):void
    {
        this.addItems([item])
    }

    public addItems(items:IOrderItem[]):void
    {
        if(!items || items.length==0)
            return;

        const orderitems = this._basketOrder && this._basketOrder.orderitems || [];
        for(let i=0;i<items.length;i++)
        {
            const item=items[i]
            const updateItem = orderitems.find(i=>i.product.id == item.product.id)
            if(updateItem)
                updateItem.quantity += item.quantity
            else
                orderitems.push(item)            
        }
        this._basketOrder.orderitems = orderitems

        this.saveBasketOrder()

        for(let i=0;i<items.length;i++)
        {
            const item=items[i]
            this._onItemAddedHandlers.forEach(handler => handler( item));
        }
    } 

    public removeItem(item:IOrderItem):void
    {
        let orderitems = this._basketOrder && this._basketOrder.orderitems || [];
        orderitems = orderitems.filter(i=> i != item)
        this._basketOrder.orderitems = orderitems
        this.saveBasketOrder()
    }
      
    public changeItem(item:IOrderItem):void
    {
        const orderitems = this._basketOrder && this._basketOrder.orderitems || [];
        if(orderitems.indexOf(item) != -1)
            this.saveBasketOrder()
    }


    private loadBasketOrder():IOrder
    {
        let order:IOrder = defaultOrder()
        const json = localStorage.getItem("basket")
        if(json)
        {
            try{
                const parsed = JSON.parse(json)
                if(parsed.orderid=="BASKET")
                    order = parsed
            } 
            catch(e){
                console.error("Error while reading basket "+e)
            }  
        }         
        this.refreshItems(order)
        return order
        
    }

    private saveBasketOrder()
    {
        if(!this._basketOrder)
            this._basketOrder = this.loadBasketOrder()
         
        this.refreshItems(this._basketOrder)
        this._basketOrder.orderid = "BASKET"
        localStorage.setItem("basket", JSON.stringify(this._basketOrder))  
    }

    public refreshItems(order:IOrder)
    {
        let totalPrice = 0
        let totalShipping = 0
        order.orderitems=order.orderitems || []
        order.orderitems.forEach(item=> {
            item.price = item.product.price * item.quantity
            item.shipping = item.product.shipping * item.quantity
            totalPrice+= item.price
            totalShipping+= item.shipping
        })
            
        order.price = totalPrice
        order.shipping = totalShipping
    }  

    public onItemAdded(handler:(item:IOrderItem)=>void):void
    {
        if(this._onItemAddedHandlers.indexOf(handler) == -1)
            this._onItemAddedHandlers.push(handler)
    }

    public offItemAdded(handler:(item:IOrderItem)=>void):void
    {
        this._onItemAddedHandlers = this._onItemAddedHandlers.filter(h=>h!=handler)
    }

    public reset():void
    {
        this._basketOrder = defaultOrder()
        this.saveBasketOrder()
    }

    public check():Promise<IOrder>
    {
        return new Promise((resolve, reject): void => { 
            api.call("basket/check", {order:this.basketOrder})
            .then(res=>{
                if(!res.hasErrors()) 
                {
                    const order = res.getItem() as IOrder
                    this._basketOrder = order
                    resolve(order) 
                }                         
                else
                    reject()
              })
            .catch(()=>reject())             
        })          
    }

    public order():Promise<IOrder>
    {
        return new Promise((resolve, reject): void => { 
            api.call("basket/order", {order:this.basketOrder})
            .then(res=>{
                if(!res.hasErrors()) 
                {
                    this.reset()
                    resolve(res.getItem()) 
                }                         
                else
                    reject()
              })
            .catch(()=>reject())             
        })       
    } 
    
    public reorder(order:IOrder):void
    {
        if(order && order.orderitems)
        {
            this.addItems(order.orderitems)
        }

    }
}


export const basket = Vue.observable(new Basket())