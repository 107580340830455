module.exports = {

    brand_title: "La Voie Lactée",

    // general
    email: "E-mail",
    password: "Mot de passe",
    password_confirm: "Mot de passe (confirmation)",
    signin: "Se connecter",
    signout: "Se déconnecter",
    error: "Erreur",
    cancel: "Annuler",
    ok: "OK",
    save: "Enregistrer",
    save_success: "Les données ont été enregistrées",
    total: "Total",
    remove: "Supprimer",
    date: "Date",
    show_details: "Voir les détails",
    hide_details: "Masquer les détails",
    all: "Tous",
    selection: "Selection",
    language: "Langue",

    // units
    unit_grams : "grammes",
    unit_kilograms : "kg",
    unit_currency: "soms",


    // menu
    login: "Connexion",
    home: "Acceuil",
    my_basket: "Mon panier",
    my_orders: "Mes commandes",
    my_profile: "Mon profil",

    // login page
    login_page_title: "Connexion",
    forgot_password: "Mot de passe oublié ?",
    
    // forgot password page
    forgot_password_page_title: "Réinitialiser le mot de passe",
    forgot_password_text: "Pour recréér votre mot passe, saisissez votre adresse email, nous vous enverrons un lien de réinitialisation",
    forgot_password_submit: "Envoyer l'email de réinitialisation",
    forgot_password_submitted: "Si votre adresse email correspond à un compte actif, un email de réinitialisation du mot de passe vous a été envoyé",
    
    // reset password page
    reset_password_page_title: "Réinitialiser le mot de passe",
    reset_password_text: "Merci de saisir vtre nouveau mot de passe",
    reset_password_submit: "Réinitialiser le mot de passe",
    reset_password_submitted: "Votre mot de passe a été changé",


    // home page
    view_basket: "Voir mon panier",    
    view_orders: "Voir mes commandes",
    our_products : "Nos produits",


    // basket
    basket: "Panier",
    basket_page_title: "Panier",
    basket_empty: "Votre panier est vide",
    add_to_basket: "Ajouter au panier",
    basket_added_item: "La produit '{product}' a été ajouté au panier",


    // products
    category: "Catégorie",
    categories: "Catégories",
    product: "Produit",
    products: "Produits",
    view_products: "Voir les produits",
    view_product: "Voir le produit",
    product_unit_price: "Prix unitaire",
    product_shipping_price: "Livraison",
    weight: "Poids",


    // order
    order_row_quantity: "Quantité",
    order_row_price: "Sous-total",
    order_price: "Montant",
    order_status: "Statut",
    order_status_basket: "Panier",
    order_status_reception: "Réception",
    order_status_production: "Production",
    order_status_shipping: "Expédition",
    order_status_delivered: "Livraison effectuée",
    order_status_deliverfailed: "Livraison impossible",
    order_status_cancelled: "Annulation",
    order_do: "Commander",
    order_confirm: "Confirmer la commande",
    order_confirmed: "Votre commande est confirmée",
    order_your_order_id: "Votre numéro de commande est :",
    order_order_id: "No de commande",
    order_confirm_failed: "Une érreur est survenue lors de la confirmation de votre commande, veuillez réessayer plus tard.",  
    order_reorder: "Commander à nouveau",
    shipping:"Livraison",
    
    
    // mail
    mail_hello: "Bonjour,",
    mail_polite: "Cordialement,",
    mail_signature: "L'équipe Lavoielactée",  

    // address
    address_name :"Nom",
    address_details :"Details",
    address_street :"Numéro / Rue",
    address_postcode :"Code postal",
    address_city :"Ville",
    address_country :"Pays",
    shipping_address :"Adresse de livraison",
    billing_address :"Adresse de facturation",
    address_use_shipping_as_billing :"Utiliser l'adresse de livraison",
    phone_number: "Téléphone", 

    // error
    error_void: "Erreur",
    error_secret_mismatch : "L'email ou le mot de passe est incorrect",
    error_invalid_user: "Utilisateur non valide",
    error_invalid_token: "Lien non valide",
    error_expired_token: "Le lien a expiré",
    error_weak_password: "Le mot de passe est trop simple",
    error_passwords_mismatch : "Les mots de passe ne correspondent pas",

    // admin
    admin: "Admin",
    admin_orders: "Commandes",
    admin_create_order: "Créer une commande",
    admin_client: "Client",
    admin_clients: "Clients",
    admin_users: "Utilisateurs",
    admin_shipping_area: "Zone de livraison",
    admin_manage: "Gérer",
    admin_append: "Ajouter",
    admin_save: "Enregistrer",
    admin_delete: "Supprimer",
    admin_confirm_delete: "Etes vous sûr de voulir supprimer cet élément",

    admin_isvisible: "Visible?",
    admin_visible: "Visible",
    admin_hidden: "Masqué",
    admin_isactive: "Actif?",
    admin_active: "Activé",
    admin_inactive: "Désactivé",
    admin_isarchived: "Archivé?",
    admin_archived: "Archivé",
    admin_nonarchived: "Visible",
    admin_archive: "Archiver",
    admin_unarchive: "Désarchiver",
    admin_name: "Nom",
    admin_slug: "Chemin",
    admin_label: "Label",
    admin_description: "Description",
    admin_sortindex: "Ordre",
    admin_image: "Image",
    admin_image_max: "max. 1Mo",

    admin_profile: "Profil",
    admin_profile_admin: "Administrateur",
    admin_profile_internal: "Interne",
    admin_profile_client: "Client",

    admin_batch: "Lot",
    admin_batchs: "Lots",
    admin_productionquantity: "Quantité produite",
    admin_remainingquantity : "Quantité disponible",
    admin_productiondate: "Date production",
    admin_expirationdate: "Date expiration",
    admin_stock: "Stock",

    // renew password
    renewpassword_email_title:"Changement de mot de passe sur lavoielactee",
    renewpassword_email_body1:"Pour changer votre mot de passe cliquez sur : ",
    renewpassword_email_body2:"Ce lien n'est valable que pendant 24 heures.",

    // languages
    locale_fr: "Français",
    locale_ru: "Russe",
    locale_ky: "Kirghize"

}