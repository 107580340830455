


















































import { Component, Vue } from 'vue-property-decorator'
import moment  from 'moment';
import { api } from '../lib/api'
import { basket } from '../lib/basket'
import { IOrder} from '../models/schemas' 
import { $$t } from '../lib/i18n' 
import LvlOrderDetail from '../components/lvl-order-detail.vue'


@Component({
   components:{LvlOrderDetail}
})
export default class MyOrdersPage extends Vue {

    public ordersFields:any[] = [
      {key:'orderid', label:'ID', sortable:true},
      {key:'date', label: $$t('date'), sortable:true, formatter : this.formatDate},
      {key:'price', label: $$t('order_price')+" ("+$$t('unit_currency')+")", sortable:true},
      {key:'status', label: $$t('order_status'), sortable:true, formatter : this.formatStatus},
      {key:'actions', label:''}
    ]

    public orders:Array<IOrder> = []

    public busy:boolean = false

    mounted() {
      this.getOrders()
    }

    getOrders():void {  
      this.busy = true  

      api.call("orders/list").then(res => {
        this.busy = false
        this.orders = res.getItems() as IOrder[]
      })   
    }

    reOrder(order:IOrder):void{
      basket.reorder(order)
    }

    formatDate(value:string):string{
      const m = moment(value,'YYYY-MM-DD"T"HH:mm:ss.SZ')
      return m.format("LL") + " ("+m.fromNow()+")"
    }

    formatStatus(value:string):string{
      return $$t('order_status_'+value.toLowerCase())
    }
}

