


























































































































































import { Component, Vue } from 'vue-property-decorator'
import { $$t } from '../lib/i18n' 
import { authentication } from '../lib/authentication'
import { basket } from '../lib/basket'
import { routes } from '../lib/routes'
import { IOrder,IOrderItem} from '../models/schemas' 
import LvlOrderDetail from '../components/lvl-order-detail.vue'

@Component({
   components:{LvlOrderDetail}
})
export default class BasketPage extends Vue {   

    private get basket(){ return basket } 
    private get routes(){ return routes } 
    private get authentication(){ return authentication } 
    private get client(){ return authentication.client } 

    private orderConfirmedPending:boolean = false
    private orderConfirmedSuccess:boolean = false
    private orderConfirmedOrderId:string=""
    private order:IOrder|undefined = undefined

    private basketFields:any[] = [
      {key:'productName', label: $$t('product') },
      {key:'productPrice', label: $$t('product_unit_price')+" ("+$$t('unit_currency')+")" },
      {key:'quantity', label: $$t('order_row_quantity') },
      {key:'price', label: $$t('order_row_price')+" ("+$$t('unit_currency')+")", formatter : this.formatCurrency },
      {key:'actions', label: '', class: 'text-right' }
    ]

    mounted() {
    }

    private removeItem(item:IOrderItem):void
    {
      basket.removeItem(item)
    }

    private changeItemQuantity(item:IOrderItem):void
    {
      basket.changeItem(item)
    }

    private clickOrder():void
    {
    this.$router.push(routes.order)       
    }



    private closeOrderModal():void
    {
      this.$bvModal.hide("order-modal")
    }

    private confirmOrder():void
    {
      this.orderConfirmedPending = true
      this.$bvModal.hide("order-modal")
      this.$bvModal.show("order-confirmed-modal")   
      this.basket.order()
        .then(order=>{
          this.orderConfirmedSuccess = true
          this.orderConfirmedOrderId = order?.orderid || ""
          this.orderConfirmedPending = false
        })  
        .catch(err=>{
          console.log(err)
          this.orderConfirmedSuccess = false
          this.orderConfirmedPending = false
        })
    }

    private closeOrderConfirmedModal():void
    {
      this.$bvModal.hide("order-confirmed-modal")
    }

    private formatCurrency(value:number):string{
      return value.toString()
    }
}

